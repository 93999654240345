<template>
  <pricing-form
    :requiredFilters="requiredFilters"
    showMarginFactor
    :priority="priority"
    :moduleSlug="moduleSlug"
    formName="everyday-pricing"
  />
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import PricingForm from "./PricingForm.vue";
import config from "../../../../config";

const { formPriority } = config;

export default {
  name: "EverydayPricing",
  metaInfo() {
    return {
      title: `${this.$t("general.everyday-pricing")} | ${this.$t(
        "general.pricing-manager"
      )}`,
    };
  },
  components: { PricingForm },
  data() {
    return {
      requiredFilters: [
        "vendorFilter",
        "categoryFilter",
        "productTagFilter",
        "vendorModelFilter",
        "skuFilter",
      ],
      priority: formPriority["everyday-pricing"],
      moduleSlug: "everyday-pricing",
    };
  },
  computed: {},
  beforeDestroy() {
    this.resetFormState();
    this.pricingSetItem({ resource: "pricingManagerId", payload: 0 });
    this.pricingSetItem({ resource: "mode", payload: "" });
    this.pricingSetItem({ resource: "preFilledData", payload: null });
  },
  methods: {
    ...mapMutations("pricingManager", {
      pricingSetItem: "setItem",
    }),
    ...mapActions(["resetFormState"]),
  },
};
</script>
